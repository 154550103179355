exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-coaching-js": () => import("./../../../src/pages/coaching.js" /* webpackChunkName: "component---src-pages-coaching-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-initiativen-js": () => import("./../../../src/pages/initiativen.js" /* webpackChunkName: "component---src-pages-initiativen-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-mdz-22-js": () => import("./../../../src/pages/mdz22.js" /* webpackChunkName: "component---src-pages-mdz-22-js" */),
  "component---src-pages-mdz-23-js": () => import("./../../../src/pages/mdz23.js" /* webpackChunkName: "component---src-pages-mdz-23-js" */),
  "component---src-pages-mdz-24-graz-js": () => import("./../../../src/pages/mdz24graz.js" /* webpackChunkName: "component---src-pages-mdz-24-graz-js" */),
  "component---src-pages-mdz-24-salzkammergut-js": () => import("./../../../src/pages/mdz24salzkammergut.js" /* webpackChunkName: "component---src-pages-mdz-24-salzkammergut-js" */),
  "component---src-pages-netzwerk-js": () => import("./../../../src/pages/netzwerk.js" /* webpackChunkName: "component---src-pages-netzwerk-js" */),
  "component---src-pages-prepage-js": () => import("./../../../src/pages/prepage.js" /* webpackChunkName: "component---src-pages-prepage-js" */)
}

